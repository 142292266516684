import { Button, Divider } from '@mui/material';
import Jitsi from 'lib-jitsi-meet';
import { useParams } from 'react-router-dom';
import { getConnectionToJitsiServer } from '../hooks/useRoom/helpers';
import { useEffect, useState } from 'react';
import Audio from './Room/partials/Audio';
import Video from './Room/partials/Video';
import { JitsiLocalTrack } from '../types/Jitsi';

const { CONNECTION_ESTABLISHED } = Jitsi.events.connection;
const { conference } = Jitsi.events;

// let chunks: any[] = [];
// let recorder: any;

// function download() {
//   let blob = new Blob(chunks, { type: 'video/webm' });
//   let url = window.URL.createObjectURL(blob);
//   let a = document.createElement('a');
//   a.style.display = 'none';
//   a.href = url;
//   a.download = 'test.webm';
//   document.body.appendChild(a);
//   a.click();
//   setTimeout(function () {
//     document.body.removeChild(a);
//     window.URL.revokeObjectURL(url);
//   }, 100);

//   recorder.stop();
// }

const Viewer = () => {
  const [connected, setConnected] = useState(false);
  const [dimensions, setDimensions] = useState<any>({});
  const [clients, setClients] = useState({});
  let { roomId } = useParams<{ roomId: string }>();
  roomId = String(roomId).toLowerCase();

  const onConnect = () => {
    setConnected(true);
    const conn = getConnectionToJitsiServer();
    // @ts-ignore
    window.progkids.connection = conn;

    conn.addEventListener(CONNECTION_ESTABLISHED, () => {
      const room = conn.initJitsiConference(roomId, {
        p2p: { enabled: false },
      });

      // @ts-ignore
      window.progkids.room = room;

      room.setLocalParticipantProperty(
        'isRecorder',
        JSON.stringify({ isRecorder: true })
      );
      room.setReceiverConstraints({
        lastN: 5,
        constraints: {},
        defaultConstraints: { idealHeight: 720, maxHeight: 720 },
      });
      // room.setAssumedBandwidthBps(100);
      room.setDisplayName('VIEWER');

      const update = (eventName: string, args: any[]) => {
        console.log('FIRE EVENT:', eventName, args);
        const participants = room.getParticipants();
        console.log('participants count', participants.length);
        const clients = participants.reduce((acc: any, participant: any) => {
          const jitsiUserId = participant.getId();

          acc[jitsiUserId] = {
            name: participant.getDisplayName(),
            tracks: participant.getTracks(),
          };

          return acc;
        }, {});

        setClients(clients);
      };

      const eventsForUpdate = [
        conference.PARTICIPANT_PROPERTY_CHANGED,
        conference.CONFERENCE_JOINED,
        conference.CONFERENCE_LEFT,
        conference.USER_LEFT,
        conference.USER_JOINED,
        conference.TRACK_ADDED,
        conference.TRACK_MUTE_CHANGED,
        conference.TRACK_REMOVED,
        conference.DOMINANT_SPEAKER_CHANGED,
      ];
      eventsForUpdate.forEach((event) => {
        room.on(event, (...args: any[]) => update(event, args));
      });

      room.join('', true);
      update('', []);
    });

    conn.connect({} as any);
  };

  useEffect(() => {
    Jitsi.setLogLevel(Jitsi.logLevels.DEBUG);
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      setDimensions(
        Object.keys(clients).reduce((acc, clientId) => {
          const client = (clients as any)[clientId];
          client.tracks.forEach((track: JitsiLocalTrack) => {
            const id = track.getId();
            const container = track.containers[0];
            if (!id || !container) return;

            const quality = container.getVideoPlaybackQuality
              ? container.getVideoPlaybackQuality()
              : {};

            acc[id] = {
              width: track.getWidth(),
              height: track.getHeight(),
              quality: {
                total: quality.totalVideoFrames,
                dropped: quality.droppedVideoFrames,
              },
            };
          });

          return acc;
        }, {} as any)
      );
    }, 2000);

    return () => {
      clearInterval(id);
    };
  }, [clients]);

  return (
    <div style={{ height: '100%', overflow: 'scroll' }}>
      {!connected && (
        <Button variant="contained" onClick={onConnect}>
          Connect
        </Button>
      )}
      <span>
        participants: <b>{Object.keys(clients).length} </b>
        <a href={`https://app.progkids.com/i/gui/new-lessons/${roomId}`}>
          Lesson link
        </a>
      </span>
      {Object.keys(clients).map((id) => {
        const client = (clients as any)[id];
        return (
          <div key={id}>
            <Divider />
            <b>
              {id} {client.name}
            </b>
            <br />
            {client.tracks.map((track: any) => {
              const id = track.getId();
              const type = track.getType();

              const isCamera = track.getVideoType() === 'camera';
              const videoStyles = { width: isCamera ? '200px' : '100%' };
              const dim = dimensions[id] || {};

              return (
                <>
                  <i>{id}</i>
                  <table>
                    <tbody>
                      <tr>
                        <td>type</td>
                        <td>muted</td>
                        <td>hasBeenMuted</td>
                        <td>active</td>
                        <td>enabled</td>
                        <td>readyState</td>
                      </tr>
                      <tr>
                        <td>{type}</td>
                        <td>{track.muted.toString()}</td>
                        <td>{track.hasBeenMuted.toString()}</td>
                        <td>{track.stream.active.toString()}</td>
                        <td>{track.track.enabled.toString()}</td>
                        <td>{track.track.readyState.toString()}</td>
                      </tr>
                    </tbody>
                  </table>

                  {type === 'audio' ? (
                    <Audio key={id} track={track} />
                  ) : (
                    <div
                      key={id}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Video track={track} style={videoStyles} />
                      {dim.width} x {dim.height} x{' '}
                      {JSON.stringify(dim.quality, null)}
                    </div>
                  )}
                  <br />
                </>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Viewer;
