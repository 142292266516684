import { io } from 'socket.io-client';
import { logEvent } from './utils/debug';

const wsServer = process.env.REACT_APP_PROXY_TARGET || '/';

export const roomSocket = io(wsServer, {
  path: '/room-socket/',
  autoConnect: false,
  transports: ['websocket'],
  reconnectionAttempts: 10,
});

const reconnectSocket = (reason: string) => {
  if (!roomSocket.connected) {
    logEvent({ event: 'roomsocket_try_reconnect', data: { reason } });
    roomSocket.connect();
  }
};

roomSocket.on('connect', () => {
  logEvent({ event: 'roomsocket_connect' });
});

roomSocket.on('reconnect', (attemptNumber) => {
  logEvent({ event: 'roomsocket_reconnect', data: { attemptNumber } });
});

roomSocket.on('reconnect_failed', () => {
  logEvent({ event: 'roomsocket_reconnect_failed' });
});

roomSocket.on('connect_error', (obj) => {
  const data = {
    msg: obj.message,
    cause: obj.cause,
    name: obj.name,
    socketId: roomSocket.id,
    active: roomSocket.active,
  };

  logEvent({ event: 'roomsocket_connect_error', data });
});

roomSocket.on('disconnect', (reason) => {
  const data = {
    reason,
    socketId: roomSocket.id,
    active: roomSocket.active,
  };

  logEvent({ event: 'roomsocket_disconnect', data });
  reconnectSocket(reason);
});
